import React from "react";


function ChallengeCard({ icono, challenge, descripcion, reward, alumno, id }) {
 
  return (
    <div>
      <div
        className={`flex flex-col justify-center rounded-3xl w-60 overflow-hidden bg-gradient-to-b from-white from-10% via-white to-black shadow-lg shadow-white/10 ${
          !alumno?.Challenges?.includes(parseInt(id)) ? "grayscale" : ""
        }`}
      >
        <img
          src={icono}
          alt={challenge}
          className="p-4 object-cover rounded-full"
        />

        <div className=" bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-700 via-gray-900 to-black flex flex-col justify-center items-center p-4 gap-4 w-full">
          <h1 className="text-red-700 text-center">{challenge}</h1>
          <p className="text-white text-center text-xs">{descripcion}</p>

          <div className="flex flex-row gap-2">
            <img
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694407560/diamonds_hlvd5r.png"
              alt="diamonds"
              className="w-6 h-6 "
            />
            <p className="text-white">{reward}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallengeCard;
