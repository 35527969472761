import React from "react";

function ProfileFoto({ foto }) {
  return (
    <div className=" ">
      <div className="relative w-72 h-72 flex justify-center items-center">
        <div className="w-56 h-56 rounded-full my-8 bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-red-300 via-red-600 to-red-900 absolute "></div>

        <div
          className="absolute w-52 h-52 bg-center bg-cover bg-no-repeat rounded-full border-8 border-black"
          style={{
            backgroundImage: `url(${foto})`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default ProfileFoto;

//<div className="w-60 h-60 rounded-full my-8 border-8 border-orange-500 absolute"></div>
