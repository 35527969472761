import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivateRoutes from "./pages/PrivateRoutes";
import Login from "./auth/Login";
import Tienda from "./pages/Tienda";
import Challenges from "./pages/Challenges";
import Do from "./pages/Do";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import { useStore } from "./store/Store";
import { useEffect } from "react";
import { supabase } from "./supabase";
import PWAPrompt from "react-ios-pwa-prompt";

export default function App() {
  const user = useStore((state) => state.user);
  const [alumno, setAlumno] = React.useState({});

  useEffect(() => {
    if (user) {
      function getData() {
        supabase
          .from("Alumnos")
          .select("*")
          .filter("Email", "eq", user.email)
          .then(({ data, error }) => {
            if (error) console.log(error);
            else setAlumno(data[0]);
          });
      }
      getData();
    }
  }, [user, setAlumno]);

  return (
    <BrowserRouter>
      {user && (
        <TopBar
          koins={alumno.Koins}
          diamonds={alumno.Diamonds}
          level={alumno.Nivel}
          id = {alumno.id}
          examen = {alumno.Examen}
        />
      )}

      
      <PWAPrompt
        copyTitle="AGREGA KUMO APP A TU PANTALLA DE INICIO"
        copyBody="Es muy importante que agregues esta app a tu pantalla de inicio para que puedas disfrutar de todas las funcionalidades."
        copyShareButtonLabel = "1) Presiona el botón de compartir"
        copyAddHomeButtonLabel = '2) Desliza hacia abajo y presiona el botón de "Agregar a inicio"'
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
        className="font-sans font-light"
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Home alumno={alumno} />} />
          <Route path="/tienda" element={<Tienda alumno={alumno} />} />
          <Route path="/challenges" element={<Challenges alumno={alumno}/>} />
          <Route path="/do" element={<Do />} />
        </Route>
      </Routes>
      {user && <BottomBar genero={alumno.Genero} />}
    </BrowserRouter>
  );
}
