import React from "react";
import { useNavigate } from "react-router-dom";

function BottomBar({genero}) {
  const navigate = useNavigate();
  return (
    <div className="w-full  fixed bottom-0 grid grid-cols-4 px-4 pt-2 pb-4 bg-black/70">
      <div className="col-span-1 flex flex-col justify-center items-center gap-1 p-2 rounded">
        <img
          className="rounded-full border border-white w-32"
          src={genero==="M"?"https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694981050/M_dn0gyl.png":"https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694981050/h_vezhd0.png"}
          alt="Tienda icon"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="col-span-1 flex flex-col justify-center items-center gap-1 p-2 rounded">
        <img
          className="rounded-full border border-white w-32"
          src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694408988/challenge_icon_s5rxr7.png"
          alt="Challenge icon"
          onClick={() => navigate("/Challenges")}
        />
      </div>
      <div className="col-span-1 flex flex-col justify-center items-center gap-1 p-2 rounded">
        <img
          className="rounded-full border border-white w-32"
          src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694409475/do_xxpvwn.png"
          alt="Do icon"
          onClick={() => navigate("/Do")}
        />
      </div>
      <div className="col-span-1 flex flex-col justify-center items-center gap-1 p-2 rounded">
        <img
          className="rounded-full border border-white w-32"
          src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694410153/store2_d91pnn.png"
          alt="Tienda icon"
          onClick={() => navigate("/Tienda")}
        />
      </div>

      <div className="col-span-1 flex flex-col justify-center items-center gap-1 bg-red-800"></div>
    </div>
  );
}

export default BottomBar;
