import React, { useEffect } from "react";
import PinField from "react-pin-field";
import { supabase } from "../supabase";
import { AiFillCloseCircle } from "react-icons/ai";

function Redeem({ id, setRedeemOpen }) {
  const [code, setCode] = React.useState("");
  const [diamondsredeem, setDiamondsredeem] = React.useState(0);

  const pinFieldProps = {
    ref: React.createRef(),
    length: 9,
    validate: /^[a-zA-Z0-9]+$/,
    format: (key) => key,
    onResolveKey: (key, ref) => {},
    onRejectKey: (key, ref) => {},
    onChange: (code) => {},
    onComplete: (code) => {
      setCode(code);
    },
    style: { backgroundColor: "white" },
    className:
      "w-12 h-12 rounded-lg bg-cover border-2 border-black text-center",
  };

  useEffect(() => {
    async function redeem() {
      const { data, error } = await supabase
        .from("Cupones")
        .select("*")
        .eq("cupon", code)
        .eq("used", false)
        .single();
        console.log(data)
      if (error) {
        const errorMessage = "error: " + error;
        console.log(errorMessage);
        alert("Cupón inválido");
        setRedeemOpen(false);
      }
      setDiamondsredeem(data?.diamonds);
    }

    if (code.length === 9) {
      redeem();
    }
  }, [code]);

  useEffect(() => {
    async function update() {
      const { data, error } = await supabase
        .from("Cupones")
        .update({ used: true })
        .eq("cupon", code)
        .single();
      if (error) console.log("error", error);
    }

    if (diamondsredeem > 0) {
      update();
    }
  }, [diamondsredeem]);

  useEffect(() => {
    async function setDiamonds() {
      const { data, error } = await supabase
        .from("Diamonds")
        .insert({ diamonds: diamondsredeem, alumno: id, motivo: "Cupón" })
        .eq("id", id)
        .single();
      if (error) console.log("error", error);
      setCode("");
      alert("Cupón canjeado con éxito");
      const msg =
        "Cupón  " +
        code +
        "  canjeado con éxito por el alumno " +
        id +
        " por " +
        diamondsredeem +
        " diamantes";
      const url = `https://kumoappback.vercel.app/telegram_msg?msg=${msg}`;
      console.log(url);
      const response = await fetch(url)
        .then((res) => {
          console.log(res);
          setRedeemOpen(false);
        })
        .then(() => {
          window.location.reload();
        });
    }

    if (diamondsredeem > 0) {
      setDiamonds();
    }
  }, [diamondsredeem]);



  return (
    <div className="w-full h-[100vh] bg-black/80 absolute top-0 flex flex-col justify-center items-center">
      <div className="w-full  flex justify-end px-10 mb-2">
        <AiFillCloseCircle
          size={30}
          color="white"
          className="self-star"
          onClick={() => setRedeemOpen(false)}
        />
      </div>
      <div className=" h-72 w-72 bg-white rounded-2xl flex flex-col justify-center items-center p-10 gap-4">
        <p className="text-center">Ingresa el cupón</p>
        <div className="flex flex-wrap   justify-center items-center gap-2">
          <PinField {...pinFieldProps} />
        </div>
      </div>
    </div>
  );
}

export default Redeem;
