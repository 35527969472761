import React, { useState, useEffect } from "react";
import ProductCard from "../components/ProductCard";
import { supabase } from "../supabase";

function Tienda({alumno}) {
  const [productos, setProductos] = useState([]);


  useEffect(() => {
    function getData() {
      supabase
        .from("ProductosApp")
        .select("*")
        .filter("Active", "eq", true)
        .order("Precio", { ascending: true })
        .then(({ data, error }) => {
          if (error) console.log(error);
          else setProductos(data);
        });
    }
    getData();
  }, []);


  return (
    <div>
      
      <div className="flex flex-row h-screen items-center gap-20 px-20 bg-black overflow-auto py-20">
        {productos.map((producto) => (
          <ProductCard
            key={producto.id}
            nombre={producto.Nombre}
            foto={producto.Foto}
            descripcion={producto.Descripcion}
            precio={producto.Precio}
            modelos={producto.Modelos}
            diamonds={alumno.Diamonds}
          />
        ))}
      </div>
     
    </div>
  );
}

export default Tienda;
