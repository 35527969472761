import React, { useEffect } from "react";
import { supabase } from "../supabase";
import { useStore } from "../store/Store";

function ProductCard({ foto, nombre, descripcion, precio, modelos, diamonds }) {
  const user = useStore((state) => state.user);
  const [alumno, setAlumno] = React.useState(null);
  const [buttonOn, setButtonOn] = React.useState(true);

  useEffect(() => {
    if (user) {
      function getData() {
        supabase
          .from("Alumnos")
          .select("*")
          .filter("Email", "eq", user.email)
          .then(({ data, error }) => {
            if (error) console.log(error);
            else setAlumno(data[0]);
          });
      }
      getData();
    }
  }, [user, setAlumno]);

  async function handleClick() {
    if (diamonds >= precio) {
      setButtonOn(false);
      const { data, error } = await supabase
        .from("PedidosApp")
        .insert([{ alumno: alumno.Nickname, producto: nombre }]);

      if (error) {
        console.error("Error inserting into PedidosApp:", error);
      } else {
        const msg = "El alumno " + alumno.Nickname + " ha comprado " + nombre;
        const url = `https://kumoappback.vercel.app/telegram_msg?msg=${msg}`;
        const response = await fetch(url).then((res) => {
          console.log(response);
          console.log("Inserted into PedidosApp:", data);
        });
      }

      const { data: data2, error: error2 } = await supabase
        .from("Diamonds")
        .insert([{ alumno: alumno.id, diamonds: precio * -1 }]);

      if (error2) {
        console.error("Error inserting into Diamonds:", error2);
      } else {
        console.log("Inserted into Diamonds:", data2);
      }

      setButtonOn(true);

      alert("Compra exitosa. Pronto recibiras tu producto 🎉🎁");
      window.location.reload();
    } else {
      alert("Necesitas más diamantes para comprar este producto");
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-center rounded-3xl w-60 overflow-hidden bg-gradient-to-b from-white from-10% via-white to-black shadow-lg shadow-white/10">
        <img src={foto} alt={nombre} className="p-4 object-cover" />

        <div className=" bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-700 via-gray-900 to-black flex flex-col justify-center items-center p-4 gap-4 w-full">
          <h1 className="text-red-700 text-center">{nombre}</h1>
          <p className="text-white text-center text-xs">{descripcion}</p>

          <div className="flex flex-row gap-2">
            <img
              src="https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694407560/diamonds_hlvd5r.png"
              alt="diamonds"
              className="w-6 h-6"
            />
            <p className="text-white">{precio}</p>
          </div>
          {diamonds >= precio ? (
            <button
              className="bg-red-700 text-white rounded-full p-2 text-xs"
              onClick={handleClick}
              disabled={!buttonOn}
            >
              {buttonOn ? "Comprar" : "Comprando..."}
            </button>
          ) : (
            <button
              className="bg-gray-700 text-white rounded-full p-2 text-xs"
              onClick={handleClick}
            >
              Comprar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
