import React from "react";

function Do() {
  const ready = false;
  return (
    !ready?<div className="bg-black text-white w-full h-screen flex justify-center items-center">Coming Soon</div>:
    <section className="flex flex-row text-white text-center overflow-x-auto">
      {/* NIVEL 1*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl">Nivel 1</h3>
          <p>Koi</p>

          <img
            className="rounded-full"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_koi_fish._japanese_style_in_black_red_50dd13a4-4962-4a36-8a35-db579d5ffd5b_shvgk2.png"
            alt="Koi"
          />
          <p>Cinta Blanca 1</p>
          <p>9 kyu</p>
        </div>
      </div>

      {/* NIVEL 2*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl">Nivel 2</h3>
          <p>Koi</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p>Cinta Blanca 2</p>
          <p>9 kyu</p>
        </div>
      </div>

      {/* NIVEL 3*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl">Nivel 3</h3>
          <p>Koi</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-red-500">perseverancia</span> eso te serviara
            para luchar en conseguir tus metas"
          </p>
          <p>Cinta Blanca 3</p>
          <p>8 kyu</p>
        </div>
      </div>
      

      {/* NIVEL 4*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-yellow-400">Nivel 4</h3>
          <p>Kame</p>

          <img
            className="rounded-full"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536734/migruedav_badge_icon_with_turtle._japanese_style_in_black_red_w_57322849-c03d-4cfd-819a-3e56f3b64160_qxwtkv.png"
            alt="Koi"
          />
          <p className="text-yellow-400">Cinta Amarilla 1</p>
          <p>8 kyu</p>
        </div>
      </div>

      {/* NIVEL 5*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-yellow-400">Nivel 5</h3>
          <p>Kame</p>

          <p className="p-10">
            "Al igual que un una tortuga avanza lentamente pero con
            determinación hacia tu objetivo."
          </p>
          <p className="text-yellow-400">Cinta Amarilla 2</p>
          <p>7 kyu</p>
        </div>
      </div>

      {/* NIVEL 6*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-yellow-400">Nivel 6</h3>
          <p>Kame</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-yellow-400">determinación</span> eso te
            serviara para luchar en conseguir tus metas"
          </p>
          <p className="text-yellow-400">Cinta Amarilla 3</p>
          <p>7 kyu</p>
        </div>
      </div>

      {/* NIVEL 7*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#fc791e]">Nivel 7</h3>
          <p>Ho-o</p>

          <img
            className="rounded-full border-white border-8"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536735/migruedav_badge_icon_with_white_phoenix_bird._japanese_style_in_f88b02ed-30eb-4e09-948e-54a84c67f69f_cvpj2y.png"
            alt="Ho-o"
          />
          <p className="text-[#fc791e]">Cinta Naranja 1</p>
          <p>6 kyu</p>
        </div>
      </div>

      {/* NIVEL 8*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#fc791e]">Nivel 8</h3>
          <p>Ho-o</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p className="text-[#fc791e]">Cinta Naranja 2</p>
          <p>6 kyu</p>
        </div>
      </div>

      {/* NIVEL 9*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#fc791e]">Nivel 9</h3>
          <p>Ho-o</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-[#fc791e]">perseverancia</span> eso te
            serviara para luchar en conseguir tus metas"
          </p>
          <p className="text-[#fc791e]">Cinta Naranja 3</p>
          <p>5 kyu</p>
        </div>
      </div>
      {/* NIVEL 10*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-green-500">Nivel 10</h3>
          <p>Hebi</p>

          <img
            className="rounded-full"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536732/migruedav_badge_icon_with_snake._japanese_style_in_black_red_wh_3c2a8461-d1de-4940-aea2-8a013ae5dcf6_che17e.png"
            alt="Hebi"
          />
          <p className="text-green-500">Cinta Verde 2</p>
          <p>5 kyu</p>
        </div>
      </div>

      {/* NIVEL 11*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-green-500">Nivel 11</h3>
          <p>Hebi</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-green-500">perseverancia</span> eso te
            serviara para luchar en conseguir tus metas"
          </p>
          <p className="text-green-500">Cinta Verde 3</p>
          <p>4 kyu</p>
        </div>
      </div>
      {/* NIVEL 12*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-green-500">Nivel 12</h3>
          <p>Hebi</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p className="text-green-500">Cinta Verde 2</p>
          <p>4 kyu</p>
        </div>
      </div>

      {/* NIVEL 13*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-blue-500">Nivel 13</h3>
          <p>Tora</p>

          <img
            className="rounded-full"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536733/migruedav_badge_icon_with_tiger._japanese_style_in_black_red_wh_de65b8d0-8ca3-43cb-a23c-a66335dac795_ehuvcq.png"
            alt="Tora"
          />
          <p className="text-blue-500">Cinta Naranja 3</p>
          <p>3 kyu</p>
        </div>
      </div>
      {/* NIVEL 14*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-blue-500">Nivel 14</h3>
          <p>Tora</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p className="text-blue-500">Cinta Naranja 2</p>
          <p>3 kyu</p>
        </div>
      </div>

      {/* NIVEL 15*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-blue-500">Nivel 15</h3>
          <p>Tora</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-blue-500">perseverancia</span> eso te serviara
            para luchar en conseguir tus metas"
          </p>
          <p className="text-blue-500">Cinta Naranja 3</p>
          <p>2 kyu</p>
        </div>
      </div>
      {/* NIVEL 16*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#835102]">Nivel 16</h3>
          <p>Ryu</p>

          <img
            className="rounded-full"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536730/migruedav_badge_icon_with_dragon._japanese_style_in_black_red_w_b14cbb51-476d-44b7-afec-2ee2e59269f5_g1q3mh.png"
            alt="Ryu"
          />
          <p className="text-[#835102]">Cinta Naranja 3</p>
          <p>2 kyu</p>
        </div>
      </div>
      {/* NIVEL 17*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#835102]">Nivel 17</h3>
          <p>Ryu</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p className="text-[#835102]">Cinta Naranja 2</p>
          <p>1 kyu</p>
        </div>
      </div>

      {/* NIVEL 18*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-[#835102]">Nivel 18</h3>
          <p>Ryu</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-[#835102]">perseverancia</span> eso te
            serviara para luchar en conseguir tus metas"
          </p>
          <p className="text-[#835102]">Cinta Naranja 3</p>
          <p>1 kyu</p>
        </div>
      </div>

      {/* NIVEL 19*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-gray-700">Nivel 19</h3>
          <p>Shisa</p>

          <img
            className="rounded-full border-white"
            src="https://res.cloudinary.com/dt9ivv2ug/image/upload/c_thumb,w_200,g_face/v1694536731/migruedav_badge_icon_with_shisa._japanese_style_in_black_red_wh_4c6c883b-3d51-4d11-a206-352eb901edc9_yqfdm1.png"
            alt="Shisa"
          />
          <p className="text-gray-700">Cinta Naranja 3</p>
          <p>1 DAN</p>
        </div>
      </div>
      {/* NIVEL 20*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-gray-700">Nivel 20</h3>
          <p>Shisa</p>

          <p className="p-10">
            "Al igual que un koi nada río arriba contra la corriente, un
            practicante de karate comienza su viaje hacia la maestría."
          </p>
          <p className="text-gray-700">Cinta Naranja 2</p>
          <p>2 DAN</p>
        </div>
      </div>

      {/* NIVEL 21*/}

      <div className="flex-none w-screen h-screen bg-black flex justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-4 blur-0">
          <h3 className="text-2xl text-gray-700">Nivel 21</h3>
          <p>Shisa</p>

          <p className="p-10">
            "Haz aprendido sobre{" "}
            <span className="text-gray-700">perseverancia</span> eso te
            serviara para luchar en conseguir tus metas"
          </p>
          <p className="text-gray-700">Cinta Naranja 3</p>
          <p>3 DAN</p>
        </div>
      </div>
    </section>
  );
}

export default Do;
