import React, { useEffect, useState } from "react";
import ChallengeCard from "../components/ChallengeCard";
import { supabase } from "../supabase";

function Challenges({alumno}) {
  const [challenges, setChallenges] = useState([]);
  
  useEffect(() => {
    function getData() {
      supabase
        .from("Challenges")
        .select("*")
        .filter("Active", "eq", true)
        .order("Diamonds", { ascending: true })
        .then(({ data, error }) => {
          if (error) console.log(error);
          else setChallenges(data);
        });
    }
    getData();
  }, []);

  console.log(challenges);
  return (
    <div className="w-full h-screen bg-black flex flex-row justify-start items-center gap-20 px-20 text-white text-center overflow-x-auto">
      {challenges.map((challenge) => (
        <ChallengeCard
          key={challenge.id}
          challenge={challenge.Challenge}
          icono={challenge.Icono}
          descripcion={challenge.Descripcion}
          reward={challenge.Diamonds}
          id={challenge.id}
          alumno={alumno}
        />
      ))}
    </div>
  );
}

export default Challenges;
